import ReactModal from "react-modal";
import styled from "styled-components";
import { media, theme } from "../../theme/theme";

export const StyledModal = styled(ReactModal) <{ width?: string, useHalfContentSplit?: boolean, customHalfSplit?: string }>`
    display: flex;
    max-height: 80vh;
    position: fixed;
    bottom: 0;
    outline: none;
    margin-right: -50%;
    background: rgb(255, 255, 255);
    overflow: initial;
    border-radius: 24px 24px 0px 0px;
    width: 100%;
    max-width: 100%;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    width: 100%;
    overflow: hidden;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        width: ${({ width }) => width ? width : "600px"};
        max-width: calc(100vw - 42px);
        border-radius: 12px;
        transform: translate(-50%, -50%);
        inset: 50% auto auto 50%;
        bottom: initial;
        position: absolute;
        max-height: 95vh;
        top: 50%;
        left: 50%;
    }

    &:after {
        content: "";
        display: none;
        width: ${({ customHalfSplit }) => customHalfSplit ? customHalfSplit : "50%"};
        background: ${({ theme }) => theme.v3.colors.grey96};
        right: 0;
        top: 0;
        position: absolute;
        height: 100%;
        z-index: -1; 
        border-radius: 12px;

        ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
            display: ${({ useHalfContentSplit }) => useHalfContentSplit ? "block" : "none"};
        }
    }
`;

export const V3ModalTitleWrapper = styled.div<{ $customPadding?: string }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    padding:  ${({ $customPadding }) => $customPadding ? "0 22px" : undefined};
`;

export const V3ModalChildrenWrapper = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    overscroll-behavior: contain;
    min-height: 80px;
`;

export const V3ModalCloseWrapper = styled.div`
    &:hover {
        cursor: pointer;
    }
`;

export const V3ModalLayout = styled.div<{ $customPadding?: string }>`
    display: flex;
    flex-direction: column;
    width: 100%;  

    > div {
        padding:  ${({ $customPadding }) => $customPadding ? $customPadding : "0 22px"};
    }
`;