import React, { useState } from "react";
import { V3TTContentWrapper, V3TTTriggerWrapper } from "./V3Tooltip.styles";
import {
    useFloating,
    useInteractions,
    useHover,
    useFocus,
    useDismiss,
    useRole,
    safePolygon,
    Placement,
    autoUpdate,
    offset,
    flip,
    shift,
    FloatingPortal,
} from "@floating-ui/react";

type V3TooltipProps = {
    referenceContent: JSX.Element,
    tooltipContent: JSX.Element | string,
    background?: string,
    placement?: Placement
    tooltipMaxWidth?: number,
    hideOnMobile?: boolean,
    customOffset?: number,
    as?: string,
    delay?: number,
    noCursor?: boolean,
}

export const V3Tooltip = ({
    referenceContent,
    tooltipContent,
    background,
    placement = "top",
    tooltipMaxWidth,
    hideOnMobile = false,
    customOffset = 10,
    as = "p",
    delay = 150,
    noCursor = false
}: V3TooltipProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        strategy: "fixed",
        onOpenChange: setIsOpen,
        placement: placement,
        // Make sure the tooltip stays on the screen
        whileElementsMounted: autoUpdate,
        middleware: [
            // Make sure tooltip is X pixels away from reference element
            offset(customOffset),
            // Make sure the tooltip is in view, even if it can't be fully centered to the element
            shift({ padding: 10 }),
            // Make sure the placement is respected as long as there is space
            flip({ fallbackAxisSideDirection: "start" }),
        ]
    });

    const hover = useHover(context, {
        handleClose: safePolygon(),
        move: false,
        delay
    });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    // Role props for screen readers
    const role = useRole(context, { role: "tooltip" });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        focus,
        dismiss,
        role
    ]);

    return (
        <>
            <V3TTTriggerWrapper
                ref={refs.setReference}
                {...getReferenceProps()}
                tabIndex={0}
                $noCursor={noCursor}
            >
                {referenceContent}
            </V3TTTriggerWrapper>
            {isOpen && <FloatingPortal>
                <V3TTContentWrapper
                    as={as as any}
                    ref={refs.setFloating}
                    style={floatingStyles}
                    {...getFloatingProps()}
                    background={background}
                    $hideOnMobile={hideOnMobile}
                    $tooltipMaxWidth={tooltipMaxWidth}
                >

                    {tooltipContent}

                </V3TTContentWrapper>
            </FloatingPortal>
            }
        </>
    );
};