const device = () => {
    const mobile = typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1;
    return mobile ? "Mobile" : "Desktop";
}
export const getPageViewPayload = (data?: any) => {
    if (process.browser) {
        return {
            referrer: document.referrer,
            device: device(),
            url: document.location.href,
            ...data
        }
    } else {
        return {}
    }
}