import { UserAccountType } from "authory-api-types/dist/enums"
import { AuthenticatedUserResponse } from "authory-api-types/dist/types"
import Link from "next/link"
import { useRouter } from "next/router"
import { useState, useRef, Dispatch, SetStateAction, MouseEventHandler, useEffect } from "react"
import { useSignOut } from "../../hooks/useSignOut"
import { V3HasAccessToBillingPage } from "../../pagelayouts/settings/billing/payment.utils"
import { APPLICATION_ROUTES } from "../../types/routes"
import { getAnalyticsStatus } from "../../utils/analyticsStatus"
import { expandSection, collapseSection } from "../../utils/collaple-expand-utils"
import { V3IconAnalytics, V3IconBell, V3IconCard, V3IconChevronRight, V3IconCollections, V3IconContent, V3IconGift, V3IconMyAccount, V3IconNewsletter, V3IconOut, V3IconOverview, V3IconPortfolio, V3IconSend, V3IconSettings, V3IconSources, V3IconSubscribers, V3IconWeb } from "../Icons"
import { ExpandablePanel } from "../ReusableStyledComponents/ExpandablePanel"
import { V3SubtitleMedium } from "../Typography"
import { MobileMenuOption, MobileNavbarLink, MobileNavBarPanel, SubMenuLink, SubMenuWrapper } from "./V3MobileNav.styles"

interface MobileMenuOptionProps {
    icon: JSX.Element,
    text: string,
    expandableContent?: JSX.Element,
    initialOpen?: boolean
}

const MobileMenuOpt = ({ icon, text, expandableContent, initialOpen = false }: MobileMenuOptionProps) => {

    const [panelOpen, setPanelOpen] = useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const contentEl = useRef<HTMLDivElement | null>(null);

    const handleOpen: MouseEventHandler<HTMLDivElement> = (e) => {
        if (expandableContent) e?.stopPropagation();

        if (!contentEl.current) return;

        if (!panelOpen) {
            expandSection(setPanelOpen, setIsTransitioning, contentEl.current);

            setTimeout(() => {
                if (contentEl.current && contentEl.current.scrollIntoView) contentEl.current.scrollIntoView({
                    block: "start",
                    inline: "nearest",
                    behavior: "smooth"
                });
            }, 200);
        } else {
            collapseSection(setPanelOpen, setIsTransitioning, contentEl.current);
        }
    }

    useEffect(() => {
        if (!initialOpen) return;
        //@ts-ignore
        handleOpen();
    }, [initialOpen]);

    return <>
        <MobileMenuOption onClick={handleOpen} $panelOpen={panelOpen || isTransitioning}>
            <div>{icon}</div>
            <V3SubtitleMedium>{text}</V3SubtitleMedium>
            {expandableContent && <div><V3IconChevronRight stroke="white" /></div>}
        </MobileMenuOption>
        {
            expandableContent && <ExpandablePanel ref={contentEl}>
                {expandableContent}
            </ExpandablePanel>
        }
    </>
}

interface V3MobileNavProps {
    open: boolean,
    user: AuthenticatedUserResponse,
    setMenuOpen: Dispatch<SetStateAction<boolean>>,
    topValue?: number,
}

export const V3MobileNav = ({ open, user, setMenuOpen, topValue = 75 }: V3MobileNavProps) => {
    const showBillingPage = V3HasAccessToBillingPage(user);
    const isBusinessParent = user?.type === UserAccountType.BusinessParent;
    const signOut = useSignOut();
    const { isWebInactive } = getAnalyticsStatus(user);

    const router = useRouter();

    const analyticsInitialOpen = [
        APPLICATION_ROUTES.ANALYTICS,
        APPLICATION_ROUTES.ANALYTICS_WEB,
    ].includes(router.route as APPLICATION_ROUTES);

    const newsletterInitialOpen = [
        APPLICATION_ROUTES.NEWSLETTER,
        APPLICATION_ROUTES.NEWSLETTER_SUBSCRIBERS,
        APPLICATION_ROUTES.NEWSLETTER_REPORT,
        APPLICATION_ROUTES.NEWSLETTER_SETTINGS,
    ].includes(router.route as APPLICATION_ROUTES);

    const settingsInitialOpen = [
        APPLICATION_ROUTES.SETTINGS_ACCOUNT,
        APPLICATION_ROUTES.SETTINGS_SOURCES,
        APPLICATION_ROUTES.SETTINGS_BILLING,
        APPLICATION_ROUTES.SETTINGS_NOTIFICATIONS,
        APPLICATION_ROUTES.SETTINGS_REFERRAL,
    ].includes(router.route as APPLICATION_ROUTES);

    return <MobileNavBarPanel $isOpen={open} $topValue={topValue - 1}>
        <ul onClick={() => setMenuOpen(false)}>
            {
                !isBusinessParent && <li>
                    <Link href={APPLICATION_ROUTES.PORTFOLIO_APP} passHref legacyBehavior>
                        <MobileNavbarLink>
                            <MobileMenuOpt
                                icon={<V3IconPortfolio />}
                                text="Portfolio"
                            />
                        </MobileNavbarLink>
                    </Link>
                </li>
            }
            {
                !isBusinessParent && <li>
                    <Link href={APPLICATION_ROUTES.COLLECTIONS} passHref legacyBehavior>
                        <MobileNavbarLink>
                            <MobileMenuOpt
                                icon={<V3IconCollections />}
                                text="Collections"
                            />
                        </MobileNavbarLink>
                    </Link>
                </li>
            }
            <li>
                <Link href={APPLICATION_ROUTES.CONTENT} passHref legacyBehavior>
                    <MobileNavbarLink>
                        <MobileMenuOpt
                            icon={<V3IconContent />}
                            text="Content"
                        />
                    </MobileNavbarLink>
                </Link>
            </li>
            {
                !isBusinessParent && !isWebInactive && <li>
                    <MobileMenuOpt
                        icon={<V3IconAnalytics />}
                        text="Social Media Impact"
                        initialOpen={analyticsInitialOpen}
                        expandableContent={
                            <SubMenuWrapper >
                                <li>
                                    <Link href={APPLICATION_ROUTES.ANALYTICS} passHref legacyBehavior>
                                        <SubMenuLink as="a">
                                            <div><V3IconOverview /></div>
                                            <div>Overview</div>
                                        </SubMenuLink>
                                    </Link>
                                </li>
                                {
                                    !isWebInactive && <li>
                                        <Link href={APPLICATION_ROUTES.ANALYTICS_WEB} passHref legacyBehavior>
                                            <SubMenuLink as="a">
                                                <div><V3IconWeb /></div>
                                                <div>Details per article</div>
                                            </SubMenuLink>
                                        </Link>
                                    </li>
                                }
                            </SubMenuWrapper>
                        }
                    />
                </li>
            }
            {
                isBusinessParent && <li>
                    <Link href={APPLICATION_ROUTES.ACCOUNTS} passHref legacyBehavior>
                        <MobileNavbarLink>
                            <MobileMenuOpt
                                icon={<V3IconCollections />}
                                text="Accounts"
                            />
                        </MobileNavbarLink>
                    </Link>
                </li>
            }
            {
                !isBusinessParent && <li>
                    <MobileMenuOpt
                        icon={<V3IconNewsletter />}
                        text="Newsletter"
                        initialOpen={newsletterInitialOpen}
                        expandableContent={
                            <SubMenuWrapper>
                                <li>
                                    <Link href={APPLICATION_ROUTES.NEWSLETTER} passHref legacyBehavior>
                                        <SubMenuLink as="a">
                                            <div><V3IconSend /></div>
                                            <div>Next newsletter</div>
                                        </SubMenuLink>
                                    </Link>
                                </li>
                                <li>
                                    <Link href={APPLICATION_ROUTES.NEWSLETTER_SUBSCRIBERS} passHref legacyBehavior>
                                        <SubMenuLink as="a">
                                            <div><V3IconSubscribers /></div>
                                            <div>Subscribers</div>
                                        </SubMenuLink>
                                    </Link>
                                </li>
                                <li>
                                    <Link href={APPLICATION_ROUTES.NEWSLETTER_REPORT} passHref legacyBehavior>
                                        <SubMenuLink as="a">
                                            <div><V3IconAnalytics width="16" height="16" /></div>
                                            <div>Reporting</div>
                                        </SubMenuLink>
                                    </Link>
                                </li>
                                <li>
                                    <Link href={APPLICATION_ROUTES.NEWSLETTER_SETTINGS} passHref legacyBehavior>
                                        <SubMenuLink as="a">
                                            <div><V3IconSettings /></div>
                                            <div>Settings</div>
                                        </SubMenuLink>
                                    </Link>
                                </li>
                            </SubMenuWrapper>
                        }
                    />
                </li>
            }
            <li>
                <MobileMenuOpt
                    icon={<V3IconSettings />}
                    text="Settings"
                    initialOpen={settingsInitialOpen}
                    expandableContent={
                        <SubMenuWrapper>
                            <li>
                                <Link href={APPLICATION_ROUTES.SETTINGS_ACCOUNT} passHref legacyBehavior>
                                    <SubMenuLink as="a">
                                        <div><V3IconMyAccount /></div>
                                        <div>My Account</div>
                                    </SubMenuLink>
                                </Link>
                            </li>
                            {
                                !isBusinessParent && <li>
                                    <Link href={APPLICATION_ROUTES.SETTINGS_SOURCES} passHref legacyBehavior>
                                        <SubMenuLink as="a">
                                            <div><V3IconSources /></div>
                                            <div>Sources</div>
                                        </SubMenuLink>
                                    </Link>
                                </li>
                            }
                            {
                                showBillingPage && <li>
                                    <Link href={APPLICATION_ROUTES.SETTINGS_BILLING} passHref legacyBehavior>
                                        <SubMenuLink as="a">
                                            <div><V3IconCard /></div>
                                            <div>Billing</div>
                                        </SubMenuLink>
                                    </Link>
                                </li>
                            }
                            <li>
                                <Link href={APPLICATION_ROUTES.SETTINGS_NOTIFICATIONS} passHref legacyBehavior>
                                    <SubMenuLink as="a">
                                        <div><V3IconBell /></div>
                                        <div>Notifications</div>
                                    </SubMenuLink>
                                </Link>
                            </li>
                            {
                                !isBusinessParent && <li>
                                    <Link href={APPLICATION_ROUTES.SETTINGS_REFERRAL} passHref legacyBehavior>
                                        <SubMenuLink as="a">
                                            <div><V3IconGift /></div>
                                            <div>Refer a Friend</div>
                                        </SubMenuLink>
                                    </Link>
                                </li>
                            }
                            <li>
                                <SubMenuLink onClick={signOut} as="span">
                                    <div><V3IconOut /></div>
                                    <div>Sign out</div>
                                </SubMenuLink>
                            </li>
                        </SubMenuWrapper>
                    }
                />
            </li>
        </ul>
    </MobileNavBarPanel>
}