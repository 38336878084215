import { useRouter } from "next/router"
import { useContext } from "react"
import Link from "next/link"
import { V3AuthoryLogo } from "../AuthoryLogo"
import { LEFT_NAV_MOBILE_MENU_BR, LeftBarMobileIconWrapper, LeftBarShowDesktop, LeftBarShowMobile, LeftNavBarWrapper, LeftNavBodyLink, LeftNavBottomPanel, LeftNavTopPanel, LeftNavbarLogoPanel } from "./LeftNavBar.styles"
import { V3IconAnalytics, V3IconCertificates, V3IconClose, V3IconCollections, V3IconContent, V3IconHelpCircle, V3IconMenu, V3IconNewsletter, V3IconPortfolio, V3IconSources } from "../Icons"
import { LeftNavBarLink } from "./LeftNavBarLink"
import { APPLICATION_ROUTES } from "../../types/routes"
import { V3Dropdown } from "../Dropdown"
import { V3Tooltip } from "../Tooltip"
import { DropdownOptionCTA } from "../DropdownOptionCTA"
import { SidebarLayoutShowDesktop } from "../../layout/SideBarLayout"
import { useMenuOpenOverflow } from "../../hooks/menuOverflowHandler"
import { V3ProfileAvatar } from "../ProfileAvatar"
import { getAuthenticatedUserClient } from "../../utils/getAuthenticatedUser"
import { getDisplayName } from "../../utils/getDisplayName"
import { CookieContext } from "../../context/cookieContext"
import { V3MobileNav } from "./V3MobileNav"
import { useWindowSize } from "../../hooks/useWindowSize"
import { theme } from "../../theme/theme"
import { UserAccountType } from "authory-api-types/dist/enums"
import { LEFT_SIDE_MENU_MOBILE_HEIGHT } from "../../layout/SideBarLayout/SidebarLayout.styles"
import { getAnalyticsStatus } from "../../utils/analyticsStatus"
import React from "react"
import { useTrail, a, useSpringRef, useChain } from "@react-spring/web"
import { InlineLink } from "../ReusableStyledComponents/InlineLink"

export const LeftNavBar = ({ animationDisabled = true }) => {
    const [menuOpen, setMenuOpen] = useMenuOpenOverflow(LEFT_NAV_MOBILE_MENU_BR);
    const router = useRouter();
    const token = useContext(CookieContext);
    const { authenticatedUser: { data } } = getAuthenticatedUserClient(token, router, true);
    const isBusinessParent = data?.type === UserAccountType.BusinessParent;

    const size = useWindowSize();
    const isMobile = size.width && size.width < theme.v3.layout.sidebarLayout.mobile || false;

    const { isWebInactive } = getAnalyticsStatus(data);

    const TopPanelElems = [];

    if (!isBusinessParent) {
        TopPanelElems.push([
            <div>
                <LeftNavBarLink title="Portfolio" href={APPLICATION_ROUTES.PORTFOLIO_APP} >
                    <V3IconPortfolio />
                </LeftNavBarLink>
            </div>,
            <div>
                <LeftNavBarLink title="Collections" href={APPLICATION_ROUTES.COLLECTIONS}>
                    <V3IconCollections />
                </LeftNavBarLink>
            </div>
        ]);
    }

    TopPanelElems.push(<div>
        <LeftNavBarLink title="Content Archive" href={APPLICATION_ROUTES.CONTENT}>
            <V3IconContent />
        </LeftNavBarLink>
    </div>)

    if (!isBusinessParent) {
        TopPanelElems.push([
            <div>
                <LeftNavBarLink
                    title={isWebInactive ? <>Social Media Impact will be available once <InlineLink style={{ textDecoration: "underline" }} href={`${APPLICATION_ROUTES.CONTENT}?target=addContent`}>you've added articles</InlineLink> to your Authory account.</> : "Social Media Impact"}
                    tooltipMaxWidth={isWebInactive ? 300 : undefined}
                    href={APPLICATION_ROUTES.ANALYTICS} active={router.route.startsWith(APPLICATION_ROUTES.ANALYTICS)}
                    inactive={isWebInactive}
                >
                    <V3IconAnalytics />
                </LeftNavBarLink>
            </div>,
            <div>
                <LeftNavBarLink title="Newsletter" href={APPLICATION_ROUTES.NEWSLETTER} active={router.route.startsWith(APPLICATION_ROUTES.NEWSLETTER)} >
                    <V3IconNewsletter />
                </LeftNavBarLink>
            </div>
        ]);
    }

    const items = React.Children.toArray(TopPanelElems);
    const topNavSpringRef = useSpringRef();
    const trail = useTrail(items.length, {
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: {
            duration: 400
        },
        ref: topNavSpringRef,
        immediate: animationDisabled,
    })

    const BottomPanelElems = [];

    if (!isBusinessParent) {
        BottomPanelElems.push([
            <SidebarLayoutShowDesktop>
                <LeftNavBarLink title="Sources" href={APPLICATION_ROUTES.SETTINGS_SOURCES} placement={isMobile ? "bottom" : "right"}>
                    <V3IconSources
                        stroke="#ABB0BA"
                        size={28}
                        strokeWidth={"0.8"}
                    />
                </LeftNavBarLink>
            </SidebarLayoutShowDesktop>,
            <SidebarLayoutShowDesktop>
                <LeftNavBarLink title="Certificates" href={APPLICATION_ROUTES.SETTINGS_CERTIFICATES} placement={isMobile ? "bottom" : "right"}>
                    <V3IconCertificates
                        stroke="#ABB0BA"
                        width={24}
                        height={24}
                        strokeWidth={0.8}
                    />
                </LeftNavBarLink>
            </SidebarLayoutShowDesktop>,
            <div>
                <V3Dropdown
                    title={"Help"}
                    placement="right-end"
                    customOffset={5}
                    renderReferenceElement={() => {
                        return <V3Tooltip
                            delay={0}
                            placement={isMobile ? "bottom" : "right"}
                            referenceContent={
                                <LeftNavBodyLink as="a" data-intercom-target="Help" $inactive={false}>
                                    <V3IconHelpCircle
                                        width={20}
                                        height={20}
                                        strokeWidth={"1"}
                                    />
                                </LeftNavBodyLink>
                            }
                            tooltipContent="Help"
                        />
                    }
                    }
                    maxWidth={221}
                    renderPopperElement={(open, setShowPopper) => {
                        if (!open) return <></>;

                        return <>
                            <DropdownOptionCTA
                                label={"Tutorials"}
                                href={APPLICATION_ROUTES.TUTORIALS}
                                target="_blank"
                                onClick={() => {
                                    setShowPopper(false);
                                }}
                            />
                            <DropdownOptionCTA
                                label={"FAQ"}
                                href={"https://authory.com/help"}
                                target="_blank"
                                onClick={() => {
                                    setShowPopper(false);
                                }}
                            />
                            <SidebarLayoutShowDesktop>
                                <DropdownOptionCTA
                                    label={"Contact us"}
                                    id="open-intercom-bubble"
                                    onClick={() => {
                                        setTimeout(() => {
                                            setShowPopper(false);
                                        }, 40);
                                    }}
                                />
                            </SidebarLayoutShowDesktop>
                        </>
                    }}
                />
            </div>
        ]);
    }

    const items2 = React.Children.toArray(BottomPanelElems);
    const bottomNavSpringRef = useSpringRef();
    const trail2 = useTrail(items2.length, {
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: {
            duration: 400
        },
        ref: bottomNavSpringRef,
        immediate: animationDisabled,
    })

    const BottomEndPanelEments = [
        <div>
            <LeftBarMobileIconWrapper onClick={() => { setMenuOpen(!menuOpen) }}>
                {
                    menuOpen ? <V3IconClose stroke={menuOpen ? "white" : undefined} /> : <V3IconMenu />
                }
            </LeftBarMobileIconWrapper>
            <LeftBarShowDesktop data-intercom-target="Settings">
                <LeftNavBarLink
                    href={APPLICATION_ROUTES.SETTINGS_ACCOUNT}
                    title="Account settings"
                    active={router.route.startsWith("/app/settings") && router.route !== APPLICATION_ROUTES.SETTINGS_CERTIFICATES && router.route !== APPLICATION_ROUTES.SETTINGS_SOURCES}
                >
                    <V3ProfileAvatar
                        size={"S"}
                        src={data?.avatarUrl[0]?.url}
                        authorName={getDisplayName(data)}
                    />
                </LeftNavBarLink>
            </LeftBarShowDesktop>
        </div>
    ];

    const items3 = React.Children.toArray(BottomEndPanelEments);
    const bottomEndNavSpringRef = useSpringRef();
    const trail3 = useTrail(items3.length, {
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: {
            duration: 400
        },
        ref: bottomEndNavSpringRef,
        immediate: animationDisabled,
    })

    const timings = animationDisabled ? undefined : [5, 6.6, 7];
    const duration = animationDisabled ? 0 : 1000;

    useChain([topNavSpringRef, bottomNavSpringRef, bottomEndNavSpringRef], timings, duration);

    return <>
        <LeftNavBarWrapper $menuOpen={menuOpen}>
            <div>
                <LeftNavbarLogoPanel>
                    <Link href={APPLICATION_ROUTES.PORTFOLIO_APP}>
                        <LeftBarShowMobile>
                            <img
                                src={menuOpen ? "/authory_static_files/images/icons/authory_logo_expanded_white.svg" : "/authory_static_files/images/icons/authory_logo_expanded.svg"}
                                style={{ width: 110, height: "auto" }}
                            />
                        </LeftBarShowMobile>
                        <LeftBarShowDesktop>
                            <V3AuthoryLogo hideCopy />
                        </LeftBarShowDesktop>
                    </Link>
                </LeftNavbarLogoPanel>
                <LeftNavTopPanel data-intercom-target="LeftNavbarNavigation">
                    {trail.map(({ ...style }, index) => (
                        <a.div key={index} style={style}>
                            <a.div>{items[index]}</a.div>
                        </a.div>
                    ))}
                </LeftNavTopPanel>
            </div>
            <div>
                <LeftNavBottomPanel>
                    {
                        !menuOpen && <>
                            {trail2.map(({ ...style }, index) => (
                                <a.div key={index} style={style}>
                                    <a.div>{items2[index]}</a.div>
                                </a.div>
                            ))}
                        </>
                    }
                    <div>
                        {trail3.map(({ ...style }, index) => (
                            <a.div key={index} style={style}>
                                <a.div>{items3[index]}</a.div>
                            </a.div>
                        ))}
                    </div>
                </LeftNavBottomPanel>
            </div>
        </LeftNavBarWrapper >
        <V3MobileNav open={menuOpen} user={data!} setMenuOpen={setMenuOpen} topValue={LEFT_SIDE_MENU_MOBILE_HEIGHT} />
    </>
}