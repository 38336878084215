import { AuthenticatedUser } from "../../types/user";
import { BaseTracker } from "../BaseTracker";

// This class is a utility helper that should be used to call MixPanel tracking methods
export class IntercomTrackerMock extends BaseTracker {
    //@ts-ignore
    private hideIntercomBubble;

    constructor(user: AuthenticatedUser, hideIntercomBubble = false) {
        super(user, process.env.NEXT_PUBLIC_INTERCOM_APP_ID);
        this.hideIntercomBubble = hideIntercomBubble;
    }

    protected initializeInternal() {
        console.log("Mock - Intercom initializeInternal");
    }

    protected trackPageViewInternal() {
        console.log("Mock - Intercom trackPageViewInternal");
    }

    protected trackEventInternal(key: string, data: any) {
        console.log("Mock - Intercom trackEventInternal", key, data);
    }

    protected shutdownInternal() {
        console.log("Mock - Intercom shutdownInternal");
    }

    getVisitorId() {
        console.log("Mock - Intercom getVisitorId");
        return "foo";
    }
}