import { ColOptWrapper } from "./DropdownOptionCTA.styles";
import React from "react";
import { OptionalLink } from "../ReusableStyledComponents/OptionalLink";
import { V3BodyGrey35 } from "../ReusableStyledComponents/V3BodyGrey35";

export enum V3DropdownCTAColor {
    color = "color",
    red = "red",
}

interface DropdownOptionCTAProps {
    icon?: JSX.Element,
    label?: string,
    variant?: V3DropdownCTAColor,
    onClick?: () => void,
    active?: boolean,
    href?: string,
    target?: string,
    skipSVGActiveEffect?: boolean,
    justifiedCentered?: boolean,
    id?: string,
    tabIndex?: number,
    customLabelElement?: JSX.Element
}

export const DropdownOptionCTA = ({ label, icon, href, variant = V3DropdownCTAColor.color, onClick, active = false, target, skipSVGActiveEffect = false, justifiedCentered = false, id, tabIndex, customLabelElement }: DropdownOptionCTAProps) => {
    return <OptionalLink href={href} target={target}>
        <ColOptWrapper
            key={label}
            $variant={variant}
            onClick={onClick}
            $active={active}
            $skipSVGActiveEffect={skipSVGActiveEffect}
            $justifiedCentered={justifiedCentered}
            id={id}
            tabIndex={tabIndex}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    onClick && onClick();
                }
            }}>
            {
                !!icon && <div>
                    {icon}
                </div>
            }
            {
                !!customLabelElement && <>{customLabelElement}</>
            }
            {
                !!label && !!label.length && <div>
                    <V3BodyGrey35>{label}</V3BodyGrey35>
                </div>
            }
        </ColOptWrapper>
    </OptionalLink >;
}