import { createGlobalStyle } from "styled-components";
import { LEFT_SIDE_MENU_WIDTH } from "../layout/SideBarLayout/SidebarLayout.styles";
import { media, theme } from "./theme";

export const GlobalStyle = createGlobalStyle`
    /* http://meyerweb.com/eric/tools/css/reset/ 
        v2.0 | 20110126
        License: none (public domain)
    */

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        line-height: 1;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    html {
        box-sizing: border-box;
    }
    *, *:before, *:after {
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
    }

    .intercom-namespace{
        position: relative;
        z-index: 5;
    }

    .intercom-lightweight-app {
        z-index: 1!important;
    }

    #intercom-container {
        display: none;

        @media (min-width: 576px) {
            display: block;
        }
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }

    .ReactQueryDevtools {
        @media print {
            display: none;
        }
    }

    .simplebar-content-wrapper {
        padding-right: 9px;
    }

    .simplebar-track.simplebar-vertical {
        width: 9px;
    }

    .simplebar-track.simplebar-horizontal {
        height: 9px;
    }

    .simplebar-track {
        opacity: 0.5;
    }

    .simplebar-hover {
        opacity: 1;
        cursor: pointer;
    }
    
    .simplebar-content-wrapper {
        overscroll-behavior: contain;
    }

    .ReactModal__Overlay {
        @media print {
            display: none;
        }
    }

    .authory-tweets .react-tweet-theme {
        --tweet-container-margin: 5px 0px;
        --tweet-body-font-size: 15px;
    }   

    .yarl__slide {
        img {
            max-width: calc(100vw - 200px)!important;
        }
    }

    .ToasterAuthoryContainer {
        margin-bottom: 67px;

        ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
            transform: translateX(${LEFT_SIDE_MENU_WIDTH / 2}px);
        }
    }
`